import React from 'react';
import { NSContact } from 'aqlrc';
import { withI18next } from '../lib/withI18n';

/*
Ce composant affiche le bouton de confirmation dans la page "contact"
Tout le traitement est dans le composant parent NSContact dans aqlrc
*/

class Contact extends NSContact {
    render() {
        const buttonTitle = this.props['button-title'] || 'Envoyer';
        return (
            <div className="formLine" ref={this.myForm}>
                <link rel="stylesheet" href="/static/css/contact.css" />
                <input type="submit" className="btn btn-primary" id="contact_submit" value={buttonTitle} />
            </div>
        );
    }
}

export default withI18next([])(Contact);
