import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Geosuggest from 'react-geosuggest';
import ModalR from 'react-responsive-modal';
import DatePicker, { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr'; // Pour mettre le datepicker en français
import { NSToast } from 'aqlrc';
import getAPIUrl from '../lib/getAPIUrl';
import { withI18next } from '../lib/withI18n';
import { getArraySchedules, addTime } from '../lib/helpers';
import { Router } from '../routes';

/*
Ce composant gère la sélection de la date de retrait avec 2 modes d'affichages :
- "1" : 2 champs (date et heure) + 1 bouton pour confirmer (page "home")
- "2" : Résumé de la date avec possibilité de la changer en pop-up de la même manière que dans le mode 1 (pages "notre-carte", "category" et "product")
*/

function convertGeoSuggest(suggest) {
    if (suggest === undefined || suggest.gmaps === undefined) return;
    const delivery = {
        street_number  : '',
        route          : '',
        locality       : '',
        postal_code    : '',
        country        : '',
        initialAddress : undefined,
    };
    for (let i = 0; i < suggest.gmaps.address_components.length; i++) {
        const valueType = suggest.gmaps.address_components[i].types[0];
        const valueLong = suggest.gmaps.address_components[i].long_name;
        const valueShort = suggest.gmaps.address_components[i].short_name;
        delivery[valueType] = valueLong;
        if (valueType === 'country') {
            delivery.isoCountryCode = valueShort;
        }
    }
    return {
        line1          : delivery.street_number + (delivery.route ? ` ${delivery.route}` : ''),
        zipcode        : delivery.postal_code,
        city           : delivery.locality,
        isoCountryCode : delivery.isoCountryCode,
        country        : delivery.country,
    };
}

function toAquilaAddress(address) {
    if(address.gmaps.address_components[0].types.includes("street_number")) {
        return {
            city           : address.gmaps.address_components[2] ? address.gmaps.address_components[2].long_name : '',
            country        : address.gmaps.address_components[5] ? address.gmaps.address_components[5].long_name : '',
            line1          : address.description.split(', ')[0],
            isoCountryCode : address.gmaps.address_components[5] ? address.gmaps.address_components[5].short_name : '',
            zipcode        : address.gmaps.address_components[6] ? address.gmaps.address_components[6].long_name : '',
        };
    } else {
        return {
            city           : address.gmaps.address_components[1] ? address.gmaps.address_components[1].long_name : '',
            country        : address.gmaps.address_components[4] ? address.gmaps.address_components[4].long_name : '',
            line1          : address.description.split(', ')[0],
            isoCountryCode : address.gmaps.address_components[4] ? address.gmaps.address_components[4].short_name : '',
            zipcode        : address.gmaps.address_components[5] ? address.gmaps.address_components[5].long_name : '',
        };
    }
}

class SelectDate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            address             : '',
            currentPOS          : {},
            dateShop            : new Date(),
            homeDelivery        : false,
            initialAddress      : '',
            isValidAddress      : false,
            timeShop            : '',
            pointsOfSale        : [],
            popupDateShop       : new Date(),
            popupHomeDelivery   : false,
            popupTimeShop       : '',
            arraySchedules      : [],
            openModal : false
        };
    }

    componentDidMount = async () => {
        registerLocale('fr', fr); // Pour mettre le datepicker en français
        const pointsOfSale = await axios.post(`${getAPIUrl()}v2/pointsOfSale/populate`, { PostBody: { filter: {}, limit: 999999 }});
        if (!pointsOfSale.data.length) {
            return;
        }
        if (window.localStorage.getItem('cart_id')) {
            const result = await axios.post(`${getAPIUrl()}v2/cart/${window.localStorage.getItem('cart_id')}`, { lang: 'fr', PostBody: { populate: ['items.id'], structure: { addresses: 1 } } });
            const currentPOS = pointsOfSale.data.find((pos) => pos._id === result.data.point_of_sale) !== undefined ? pointsOfSale.data.find((pos) => pos._id === result.data.point_of_sale) : pointsOfSale.data[0];
            if (result.data.orderReceipt.date) {
                const dateShop = result.data.orderReceipt.date ? new Date(result.data.orderReceipt.date) :  new Date();
                const timeShop = result.data.orderReceipt.date ? moment(new Date(result.data.orderReceipt.date)).format('HH[h]mm') : '';

                this.setState({
                    pointsOfSale     : pointsOfSale.data,
                    dateShop,
                    timeShop,
                    homeDelivery     : result.data.orderReceipt.method === 'delivery',
                    initialAddress   : result.data.orderReceipt.method === 'delivery' && result.data.addresses && result.data.addresses.delivery ? ([result.data.addresses.delivery.line1, result.data.addresses.delivery.city, result.data.addresses.delivery.country]).join(', ') : '',
                    isValidAddress   : result.data.orderReceipt.method === 'delivery' && result.data.addresses && result.data.addresses.delivery,
                    popupDateShop    : dateShop,
                    popupHomeDelivery: result.data.orderReceipt.method === 'delivery',
                    popupTimeShop    : timeShop,
                    currentPOS
                }, () => this.getSchedules('1'));
            } else {
                this.setState({ pointsOfSale: pointsOfSale.data, currentPOS }, () => this.getSchedules());
            }
        } else {
            this.setState({ pointsOfSale: pointsOfSale.data, currentPOS: pointsOfSale.data[0] }, () => this.getSchedules());
        }
    }

    onSuggestSelect = async (suggest) => {
        const { pointsOfSale } = this.state;
        const delivery_address = convertGeoSuggest(suggest);
        if (!delivery_address) {
            return this.setState({ isValidAddress: false })
        }
        if (!delivery_address.zipcode || !delivery_address.line1 || !delivery_address.city) {
            return this.setState({ isValidAddress: false }, () => NSToast.warn('Merci de renseigner une adresse plus précise'));
        }
        // On vérifie si un point de vente peut livrer cette adresse
        try {
            const result = await axios.post(`${getAPIUrl()}pointsOfSale/findForDelivery`, delivery_address);
            if (result.data.code === 'CAN_BE_DELIVERED') {
                this.setState({
                    address        : suggest,
                    initialAddress : ([delivery_address.line1, delivery_address.city, delivery_address.country]).join(', '),
                    isValidAddress : true,
                    currentPOS     : result.data.data,
                }, () => this.getSchedules());
            } else {
                this.setState({
                    currentPOS     : pointsOfSale.find(p => p.isDelivery),
                    isValidAddress : false,
                    initialAddress : ([delivery_address.line1, delivery_address.city, delivery_address.country]).join(', ')
                });
                NSToast.warn('Cette adresse ne peut pas être livrée.');
            }
        } catch (error) {
            console.error(error);
            NSToast.warn('Adresse invalide');
            this.setState({ isValidAddress: false });
        }
    }

    handleChangeMode = (e, popup = false) => {
        if (e.target.value === 'ubereat') {
            // Redirect to ubereat in new tab
            return window.open('https://www.ubereats.com/fr/store/suki-sushi/6uum1uOvTr66zD5xenqBmg', '_blank');
        }
        if (e.target.value === 'deliveroo') {
            return window.open('https://deliveroo.fr/en/menu/saint-raphael-frejus/saint-raphael-frejus-centre-ville/suki-sushi/', '_blank');
        }
        const { pointsOfSale } = this.state;
        const posWithDrawal = pointsOfSale.find(p => p.isWithdrawal);
        const posDelivery = pointsOfSale.find(p => p.isDelivery);
        if (popup) {
            this.setState({ currentPOS: Number(e.target.value) ? posDelivery : posWithDrawal, popupHomeDelivery: Number(e.target.value) }, () => this.getSchedules())
        } else {
            this.setState({ currentPOS: Number(e.target.value) ? posDelivery : posWithDrawal, homeDelivery: Number(e.target.value), popupHomeDelivery: Number(e.target.value) }, () => this.getSchedules())
        }
    }

    getSchedules = (submit = '0') => {
        const {
            currentPOS, popupDateShop, timeShop
        } = this.state;
        if (moment().add(currentPOS.prepareDelay, 'minutes').format('YYYYMMDDHm') > moment(popupDateShop).format('YYYYMMDDHm')) {
            const newDateShop = new Date();
            const arraySchedules = getArraySchedules(currentPOS, newDateShop);
            const ts = arraySchedules && arraySchedules[0] ? arraySchedules[0] : '';
            this.setState({
                popupDateShop : newDateShop,
                popupTimeShop : ts,
                arraySchedules
            }, submit === '1' ? () => this.submitShopReceipt('0') : () => {});
        } else {
            const arraySchedules = getArraySchedules(currentPOS, popupDateShop);
            const ts = arraySchedules && arraySchedules[0] ? (timeShop !== '' && arraySchedules.includes(timeShop) ? timeShop : arraySchedules[0]) : '';
            this.setState({
                popupTimeShop : ts,
                arraySchedules
            });
        }
    }

    submitShopReceipt = async (toast) => {
        const {
            address, currentPOS, isValidAddress, popupDateShop, popupHomeDelivery, popupTimeShop
        } = this.state;
        const cartId = window.localStorage.getItem('cart_id');
        if (popupHomeDelivery) {
            if (!isValidAddress) {
                return NSToast.warn('L\'adresse de livraison est invalide / non livrable !'); 
            }

            const dateToSend = popupTimeShop.replace('h', ':');
            const body = {
                pointOfSale   : currentPOS,
                cartId,
                receiptDate   : new Date(`${moment(popupDateShop).format('MM/DD/YYYY')} ${dateToSend}`),
                receiptMethod : 'delivery',
                country       : 'fr',
                dateToSend
            };
            const response = await axios.put(`${getAPIUrl()}pointOfSale/orderReceipt`, body);
            if (response.data.data._id) {
                if (address.gmaps === undefined) {
                    this.setState({ dateShop: popupDateShop, homeDelivery: popupHomeDelivery, timeShop: popupTimeShop }, () => {
                        window.localStorage.setItem('cart_id', response.data.data._id)
                        this.onCloseModalSelectDate('0');
                    });
                    if (toast === '1') {
                        NSToast.success('Informations enregistrées !');
                        return true;
                    }
                }
                const addresses = {
                    billing : toAquilaAddress(address),
                    delivery : toAquilaAddress(address)
                };
                const res = await axios.put(`${getAPIUrl()}v2/cart/addresses`, { cartId: response.data.data._id, addresses });
                if (res.status === 200) {
                    this.setState({ dateShop: popupDateShop, homeDelivery: popupHomeDelivery, timeShop: popupTimeShop }, () => {
                        window.localStorage.setItem('cart_id', response.data.data._id)
                        this.onCloseModalSelectDate('0');
                    });
                    if (toast === '1') {
                        NSToast.success('Informations enregistrées !');
                    }
                }
            }
        } else {
            if (!popupTimeShop) { return NSToast.error('Retrait non disponible ce jour !'); }
            const dateToSend = popupTimeShop.replace('h', ':');
            const body = {
                pointOfSale   : currentPOS,
                cartId,
                receiptDate   : new Date(`${moment(popupDateShop).format('MM/DD/YYYY')} ${dateToSend}`),
                receiptMethod : 'withdrawal',
                dateToSend
            };
            try {
                const response = await axios.put(`${getAPIUrl()}pointOfSale/orderReceipt`, body);
                if (response.data.code === 'CART_UPDATED' || response.data.code === 'CART_CREATED') {
                    this.setState({ dateShop: popupDateShop, homeDelivery: popupHomeDelivery, timeShop: popupTimeShop }, () => {
                        window.localStorage.setItem('cart_id', response.data.data._id);
                        this.onCloseModalSelectDate('0');
                    });
                    if (toast === '1') {
                        NSToast.success('Créneau modifié !');
                    }
                }
            } catch (err) {
                console.error(err);
                NSToast.error(err.response.data.message);
                return false;
            }
        }
        return true;
    };

    onSubmit = async (e) => {
        e.preventDefault(); 
        if (await this.submitShopReceipt('1')) {
            Router.pushRoute('staticI18n', { _slug: 'notre-carte' });
        }
    }

    openModalSelectDate = () => {
        const { setOpenModalSelectDate } = this.props;
        this.getSchedules();
        if (setOpenModalSelectDate) {
            setOpenModalSelectDate(true)
        } else {
            this.setState({ openModal: true });
        }
    };

    onCloseModalSelectDate = () => {
        const { setOpenModalSelectDate } = this.props;
        if (setOpenModalSelectDate) {
            setOpenModalSelectDate(false);
        } else {
            this.setState({ openModal: false });
        }
    };

    render() {
        const { openModalSelectDate } = this.props;
        const {
            arraySchedules, dateShop, currentPOS, homeDelivery, initialAddress, openModal, popupHomeDelivery, popupDateShop, popupTimeShop, timeShop
        } = this.state;
        const now = moment().format('DD/MM/YYYY');
        return (
            this.props.mode === '1' ? (
                <>
                    <div className="header__slider__wrap">
                        <form className="header__slider__form" onSubmit={this.onSubmit}>
                            <div className="form-group">
                                <select className="form-control form-control-lg" value={homeDelivery ? 1 : 0} onChange={this.handleChangeMode}>
                                    {
                                        this.state.pointsOfSale.map(p => (
                                            <option value={p.isWithdrawal ? '0' : '1'}>{p.isWithdrawal ? 'Retrait' : 'Livraison'}</option>
                                        ))
                                    }
                                    <option value="ubereat">Livraison par Uber Eat</option>
                                    <option value="deliveroo">Livraison par Deliveroo</option>
                                </select>
                            </div>
                            {
                                homeDelivery ? (
                                    <div className="form-group">
                                        <Geosuggest
                                            placeholder="Saisir une adresse"
                                            inputClassName="form-control form-control-lg"
                                            initialValue={initialAddress}
                                            onSuggestSelect={this.onSuggestSelect}
                                            minLength={4}
                                            queryDelay={300}
                                            country="fr"
                                            types={['geocode']}
                                            onChange={(value) => this.setState({ initialAddress: value })}
                                            ref={(el) => this.input = el}
                                            required
                                            suggestItemActiveClassName="current"
                                        />
                                    </div>
                                ) : null
                            }
                            <div className="form-group">
                                <label htmlFor="dateShop">Date et heure</label>
                                <DatePicker
                                    minDate={new Date()}
                                    value={moment(dateShop).format('DD/MM/YYYY')}
                                    selected={new Date(dateShop)}
                                    locale="fr"
                                    onChange={(date) => this.setState({ dateShop: date, popupDateShop: date }, () => this.getSchedules('0'))}
                                    required
                                    id="dateShop"
                                    className="form-control form-control-lg"
                                />
                            </div>
                            <div className="form-group">
                                <select className="form-control form-control-lg" value={popupTimeShop} disabled={arraySchedules.length === 0} onChange={(e) => this.setState({ timeShop: e.target.value, popupTimeShop: e.target.value })}>
                                    <option value="">{arraySchedules.length > 0 ? `Heure de ${homeDelivery ? 'livraison' : 'retrait'}` : `${homeDelivery ? 'Livraison' : 'Retrait'} non disponible ce jour` }</option>
                                    {
                                        arraySchedules.map((s, index) => {
                                            const s2 = arraySchedules.length === index + 1 ? addTime(s, 15, arraySchedules[arraySchedules.length - 1]) : addTime(s, 60, arraySchedules[arraySchedules.length - 1]) ;
                                            return (
                                                <option key={s} value={s}>{s}{homeDelivery ? `-${s2}` : null}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-order" disabled={!popupTimeShop}>
                                    <i className="icon-order" />
                                    <span>Valider pour commande {homeDelivery ? 'à livrer': 'à emporter à FRÉJUS'}</span>
                                </button>
                                <br />
                                <p style={{ fontSize: '17px', color: 'red', textAlign: 'center'}}>En cas d’indisponibilité des créneaux vous pouvez contacter votre restaurant au 04.94.51.35.58</p>
                            </div>
                        </form>
                    </div>
                </>
            ) : (
                <>
                    <div className="header__banner__wrap">
                        <div className="header__banner__info">
                            <div className="container">
                                <div className="row">
                                    {
                                        !currentPOS._id ? (
                                            <div style={{ margin: 'auto' }}>Aucun point de retrait</div>
                                        ) : (
                                            <>
                                                <div className="col">
                                                    <h2 className="header__banner__cartname">
                                                        <i className="icon-cart" />
                                                        <span>EN {homeDelivery ? 'LIVRAISON' : 'RETRAIT'}</span>
                                                        <button type="button" className="header__banner__link" onClick={this.openModalSelectDate} style={{ marginLeft: '10px'}}>Changer</button>
                                                    </h2>
                                                </div>
                                                <div className="col">
                                                    <h3 className="header__banner__title">{homeDelivery ? 'Livraison prévue' : 'Retrait prévu'} : {now === moment(dateShop).format('DD/MM/YYYY') ? 'Aujourd\'hui' : moment(dateShop).format('DD/MM/YYYY')} {timeShop}</h3>
                                                    <button type="button" className="header__banner__link" onClick={this.openModalSelectDate}>Autre créneau</button>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <ModalR
                        animationDuration={0} classNames={{ modal: 'modifier-popup active selectDate', overlay: 'popup active' }}
                        open={openModalSelectDate || openModal} onClose={() => this.onCloseModalSelectDate('1')} center showCloseIcon={false}
                    >
                        <div className="modifier-popup__wrap">
                            <button type="button" className="btn btn-link modifier-popup__close" onClick={() => this.onCloseModalSelectDate('1')} />
                            <h3 className="modifier-popup__header">MODIFIER L'HEURE DE {popupHomeDelivery ? 'LIVRAISON' : 'RETRAIT'}</h3>
                            <form onSubmit={(e) => { e.preventDefault(); this.submitShopReceipt('1'); }}>
                                <div className="form-group">
                                    <select className="form-control" value={popupHomeDelivery ? 1 : 0} onChange={(e) => this.handleChangeMode(e, true)}>
                                        {
                                            this.state.pointsOfSale.map(p => (
                                                <option value={p.isWithdrawal ? '0' : '1'}>{p.isWithdrawal ? 'Retrait' : 'Livraison'}</option>
                                            ))
                                        }
                                        <option value="ubereat">Livraison par Uber Eat</option>
                                        <option value="deliveroo">Livraison par Deliveroo</option>
                                    </select>
                                    {
                                        popupHomeDelivery ? (
                                            <Geosuggest
                                                placeholder="Saisir une adresse"
                                                inputClassName="form-control"
                                                initialValue={initialAddress}
                                                onSuggestSelect={this.onSuggestSelect}
                                                minLength={4}
                                                queryDelay={300}
                                                country="fr"
                                                types={['geocode']}
                                                onChange={(value) => this.setState({ initialAddress: value })}
                                                ref={(el) => this.input = el}
                                                required
                                                suggestItemActiveClassName="current"
                                            />
                                        ) : null
                                    }
                                </div>
                                <div className="form-group">
                                    <label className="control-label" htmlFor="dateShop">Date et heure</label>
                                    <DatePicker
                                        minDate={new Date()}
                                        value={moment(popupDateShop).format('DD/MM/YYYY')}
                                        selected={new Date(popupDateShop)}
                                        locale="fr"
                                        onChange={(date) => this.setState({ popupDateShop: date }, () => this.getSchedules('0'))}
                                        required
                                        id="dateShop"
                                        className="form-control"
                                    />
                                    <select className="form-control" value={popupTimeShop} disabled={arraySchedules.length === 0} onChange={(e) => this.setState({ popupTimeShop: e.target.value })}>
                                        <option value="">{arraySchedules.length > 0 ? `Heure de ${popupHomeDelivery ? 'livraison' : 'retrait'}` : `${popupHomeDelivery ? 'Livraison' : 'Retrait'} non disponible ce jour` }</option>
                                        {
                                            arraySchedules.map((s, index) => {
                                                const s2 = arraySchedules.length === index + 1 ? addTime(s, 15, arraySchedules[arraySchedules.length - 1]) : addTime(s, 60, arraySchedules[arraySchedules.length - 1]) ;
                                                return (
                                                    <option key={s} value={s}>{s}{popupHomeDelivery ? `-${s2}` : null}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="form-footer">
                                    <button type="submit" className="btn btn-primary" disabled={!popupTimeShop}>Enregistrer</button>
                                </div>
                            </form>
                        </div>
                    </ModalR>
                </>
            )
        );
    }
}

export default withI18next([])(SelectDate);
