import React from 'react';

/**
 * @function getTime
 * @param  {type} da             tableau d'horaires du pointOfSale d'Aquila
 * @param  {type} date           date de livraison
 * @param  {type} isDeliveryShop est a true si l'on recupere la commande en magazin
 * @return {type} retourne un tableau contenant les heure de livraison ex: [12, 13, 19, 20, 21]
 */
export function getTime(da, date, isDeliveryShop = true, delay = 1) {
    if (da === undefined) throw new Error('invalid params (dateAvailability) for function getTime (AddressSelect)');
    if (date === undefined) throw new Error('invalid params (date) for function getTime (AddressSelect)');
    const day = new Date(date).getDay() === 0 ? 6 : new Date(date).getDay() - 1;
    const timeLine = da[day];
    const schedules = [];
    // heure de retrait en magasin si la commande est faite avant 16h et que le retrait se fait le jour de la commande
    const hourWithdrawOrderDay = 0;
    let isToday = false;
    // Permet d'indiquer en heure le temps a ajouter pour que le client puisse venir récupérer ou recevoir sa commande
    const nbHourForDelivery = delay;
    // On check si la commande est pour aujourd'hui
    if (new Date(date).toDateString() === new Date().toDateString()) {
        isToday = true;
    }
    if (timeLine && timeLine.minHour1 && timeLine.maxHour1) {
        const splittedMinHour = timeLine.minHour1.split('h');
        let minHour1;
        if (splittedMinHour.length === 2 &&  splittedMinHour[1] === '30') {
            minHour1 = Number(splittedMinHour[0]) + delay;
        } else {
            minHour1 = Number(splittedMinHour[0]);
        }
        const maxHour1 = Number(timeLine.maxHour1.split('h')[0]);
        // Si la commande est pour ajourd'hui alors minHour devient l'heure de commande + 30mins
        const hourOrder = new Date().getHours()  + nbHourForDelivery;
        // Si l'heure min = 10h et qu'on commande a 6h + 2 (8h) on itérera a partir de 10h
        let minHour;
        if (isToday) {
            // Retrait en magasin, a partir de 17h si la commande est passé avant 16h
            if (minHour1 >= hourOrder) {
                minHour = minHour1;
            } else {
                minHour = hourOrder;
            }
            // if (isDeliveryShop && new Date().getMinutes() < 30 && schedules.length === 0 && new Date().getHours() < minHour) schedules.push(minHour - 1);
        } else {
            minHour = minHour1;
        }
        const maxHour = maxHour1;
        while (minHour !== maxHour + delay && minHour < maxHour) {
            schedules.push(minHour);
            minHour++;
        }
    }
    if (timeLine && timeLine.minHour2 && timeLine.maxHour2) {
        const minHour2 = Number(timeLine.minHour2.split('h')[0]);
        const maxHour2 = Number(timeLine.maxHour2.split('h')[0]);
        const hourOrder = new Date().getHours() + nbHourForDelivery;
        // Si l'heure min = 15h et qu'on commande a 10h + 2 (12h) on itérera a partir de 15h
        let minHour;
        if (isToday) {
            if (minHour2 >= hourOrder) {
                minHour = minHour2;
            } else {
                minHour = hourOrder;
            }
            // if (isDeliveryShop && new Date().getMinutes() < 30 && schedules.length === 0 && new Date().getHours() < minHour) schedules.push(minHour - 1);
        } else {
            minHour = minHour2;
        }
        const maxHour = maxHour2;
        while (minHour !== maxHour + delay && minHour < maxHour) {
            schedules.push(minHour);
            minHour++;
        }
    }
    schedules.sort((a, b) => a - b);
    // On supprime les valeurs identiques
    const tSchedules = Array.from(new Set(schedules));
    return tSchedules;
}

/**
 * @function getJsxSchedules
 * @param  {type} homeDelivery vaut true si la livraison est a domicile
 * @param  {type} currentPOS   PointOfSale actuellement selectionné
 * @param  {type} date         date de livraison
 * @param  {type} delay        nombre d'heure de delai entre l'heure de commande et l'heure de livraison/recup
 * @return {type} Retourne le tableau d'horaire ainsi que les options qui seront afficher dans le <select /> d'heure
 */
export function getJsxSchedules(homeDelivery, currentPOS, date, delay = 1) {
    const isToday = new Date(date).toDateString() === new Date().toDateString();
    const schedules = getTime(currentPOS.deliveryAvailability, date, !homeDelivery, delay);
    const arraySchedules = [];
    const jsxSchedules = [];
    for (let i = 0; i < schedules.length; i++) {
        if (isToday && (homeDelivery && new Date().getMinutes() < 30 && new Date().getHours() >= schedules[i] - delay && i === 0)) {
            arraySchedules.push(`${schedules[i]}h30`);
            jsxSchedules.push(<React.Fragment key={i}>
                <option value={`${schedules[i]}h30`}>{`${schedules[i]}h30`}</option>
            </React.Fragment>);
        } else if (isToday && (homeDelivery && new Date().getMinutes() > 30 && new Date().getHours() >= schedules[i] - delay && i === 0)) {
            continue;
        } else if (isToday && (!homeDelivery && new Date().getMinutes() > 30 && new Date().getHours() >= schedules[i] - delay  && i === 0)) {
            arraySchedules.push(`${schedules[i]}h30`);
            jsxSchedules.push(<React.Fragment key={i}>
                <option value={`${schedules[i]}h30`}>{`${schedules[i]}h30`}</option>
            </React.Fragment>);
        } else {
            arraySchedules.push(`${schedules[i]}h00`, `${schedules[i]}h30`);
            jsxSchedules.push(<React.Fragment key={i}>
                <option value={`${schedules[i]}h00`}>{`${schedules[i]}h00`}</option>
                <option value={`${schedules[i]}h30`}>{`${schedules[i]}h30`}</option>
            </React.Fragment>);
        }
    }
    return { schedules, arraySchedules, jsxSchedules };
}

/**
 * @function getArraySchedules
 * @param  {type} currentPOS   PointOfSale actuellement selectionné
 * @param  {type} date         Date de livraison
 * @param  {type} delay        Délai de préparation en minutes
 * @return {type} Retourne le tableau global des horaires
 */
export function getArraySchedules(currentPOS, date) {
    const nowTimestamp = Math.trunc(Date.now() / 1000);
    const selDate = new Date(date);
    const selDay = selDate.getDay() === 0 ? 6 : selDate.getDay() - 1;
    const timeLine = currentPOS.deliveryAvailability[selDay]; // Horaires du jour sélectionné
    const step = currentPOS.timeSlot ? Number(currentPOS.timeSlot) : 30; // Créneau horaire (en minutes)

    const array = [];
    if (timeLine) {
        const year = selDate.getFullYear(); // Année de la date sélectionnée
        const month = selDate.getMonth();   // Mois de la date sélectionnée
        const day = selDate.getDate();      // Jour de la date sélectionnée

        // On détermine le nombre de commandes par horaire du jour sélectionné
        const orders = {};
        // 1) On regarde dans les commandes provenant du site
        if (currentPOS.orders && currentPOS.orders.length) {
            for (let i = 0; i < currentPOS.orders.length; i++) {
                const order = currentPOS.orders[i];
                const date_order = new Date(order.date);
                if (`${year}-${month}-${day}` === `${date_order.getFullYear()}-${date_order.getMonth()}-${date_order.getDate()}`) {
                    const hour = (`0${date_order.getHours()}`).substr(-2);
                    const minute = (`0${date_order.getMinutes()}`).substr(-2);
                    const index = `${hour}h${minute}`;
                    orders[index] = orders[index] ? orders[index] + 1 : 1;
                }
            }
        }
        // 2) On regarde dans les commandes provenant du dashboard
        if (currentPOS.ordersExtern && currentPOS.ordersExtern.length) {
            for (let i = 0; i < currentPOS.ordersExtern.length; i++) {
                const order = currentPOS.ordersExtern[i];
                const date_order = new Date(order.date);
                if (`${year}-${month}-${day}` === `${date_order.getFullYear()}-${date_order.getMonth()}-${date_order.getDate()}`) {
                    const hour = (`0${date_order.getHours()}`).substr(-2);
                    const minute = (`0${date_order.getMinutes()}`).substr(-2);
                    const index = `${hour}h${minute}`;
                    orders[index] = orders[index] ? orders[index] + 1 : 1;
                }
            }
        }

        // On boucle sur les 2 créneaux
        for (let i = 1; i <= 2; i++) {
            const min = timeLine[`minHour${i}`];
            const max = timeLine[`maxHour${i}`];
            if (min && max) {
                const [minHour, minMinute] = min.split('h');
                const minTimestamp = Math.trunc(new Date(year, month, day, minHour, minMinute, 0).getTime() / 1000); // Timestamp min

                const [maxHour, maxMinute] = max.split('h');
                const maxTimestamp = Math.trunc(new Date(year, month, day, maxHour, maxMinute, 0).getTime() / 1000); // Timestamp max

                // On détermine les horaires en fonction du min et du max
                let t = minTimestamp;
                while (t <= maxTimestamp) {
                    if (t >= nowTimestamp + (currentPOS.prepareDelay * 60)) {
                        const hour = (`0${new Date(t * 1000).getHours()}`).substr(-2);
                        const minute = (`0${new Date(t * 1000).getMinutes()}`).substr(-2);
                        const slot = `${hour}h${minute}`;

                        // Si l'horaire n'est pas complet, on l'ajoute
                        if (!orders[slot] || (orders[slot] && orders[slot] < currentPOS.maxOrdersPerSlot)) {
                            array.push(slot);
                        }
                    }
                    t += step * 60; // On augmente en fonction de l'intervalle passé en paramètre
                }
            }
        }
    }

    return array;
}

export function addTime(schedule, add, max) {
    if (!schedule) return '';

    const hmax = max.replace('h', ':');
    const tmax = Math.trunc(new Date('1970-1-1 ' + hmax).getTime() / 1000) + (15 * 60);

    const h = schedule.replace('h', ':');
    const t = Math.trunc(new Date('1970-1-1 ' + h).getTime() / 1000);
    let t2 = t + (add * 60);
    if (t2 > tmax) t2 = tmax;
    const hour = (`0${new Date(t2 * 1000).getHours()}`).substr(-2);
    const minute = (`0${new Date(t2 * 1000).getMinutes()}`).substr(-2);
    return `${hour}h${minute}`;
}