import React from 'react';
import axios from 'axios';
import {
    NSContext, NSProductCard, NSToast, CartLib
} from 'aqlrc';
import moment from 'moment';
import PropTypes from 'prop-types';
import ModalR from 'react-responsive-modal';
import MenuProduct from './MenuProduct';
import getAPIUrl from '../lib/getAPIUrl';
import { withI18next } from '../lib/withI18n';
import { Link } from '../routes';

class ProductCard extends NSProductCard {
    constructor(props, context) {
        super(props, context);
        this.state = {
            openModalEditMenu : false,
            product           : props.type === 'data' ? props.value : context.props[`nsProductCard_${props.type}_${props.value}`],
            exceptions        : ['930_271', '944_286']
        };
        this.formMenu = {};
    }

    createMenuSection = (section, inputs) => {
        if (inputs.length === undefined || inputs.type === 'select-one') {
            if ((inputs.type === 'radio' && inputs.checked === true) || (inputs.type === 'select-one' && inputs.value && inputs.value !== '')) { return { bundle_section_ref: section.ref, products: [inputs.value] }; }
            if (section.isRequired && ((inputs.type === 'radio' && inputs.checked === false) || (inputs.type === 'select-one' && inputs.value === ''))) { throw `Veuillez sélectionner au moins 1 ${section.ref}`; }
        }

        const checkedInputs = [];
        for (let i = 0; i < inputs.length; i++) {
            if (inputs[i].checked === true) { checkedInputs.push(inputs[i]); }
        }
        if (section.isRequired && checkedInputs.length === 0) {
            throw `Veuillez sélectionner au moins 1 ${section.ref.toLowerCase()}`;
        }
        const values = [];
        for (let i = 0; i < checkedInputs.length; i++) {
            values.push(checkedInputs[i].value);
        }
        if (values.length < section.minSelect) {
            throw `Vous devez sélectionner au moins ${section.minSelect} ${section.ref.toLowerCase()}${section.minSelect > 1 ? 's' : null}`;
        }
        if (values.length > section.maxSelect) {
            throw `Vous ne pouvez sélectionner plus de ${section.maxSelect} ${section.ref.toLowerCase()}${section.maxSelect > 1 ? 's' : null}`;
        }
        return { bundle_section_ref: section.ref, products: values };
    }

    addToCart = async (e) => {
        e.preventDefault();
        const { setOpenModalSelectDate } = this.props;
        const { exceptions, product } = this.state;

        const now = new Date();
        let verifDate = new Date();
        const cartId = window.localStorage.getItem('cart_id');
        if (cartId) {
            // Requête pour sélectionner le panier (la route v2 permet de populate les données produits pour les menus)
            const resCart = await axios.post(`${getAPIUrl()}v2/cart/${cartId}`, { lang: 'fr' });
            if (resCart.status !== 500 && resCart.data && resCart.data.orderReceipt.date) {
                verifDate = resCart.data.orderReceipt.date;
            }
        }

        // EXCEPTION EN DUR validée par Bertrand et Vincent
        // Les formules midi ne sont commandables que le matin
        if ((Number(moment(verifDate).format('HHmm')) >= 1400 || Number(moment(verifDate).format('d')) === 0 || Number(moment(verifDate).format('d')) === 6) && exceptions.includes(product.code)) {
            this.setState({ openModalEditMenu: false });
            setOpenModalSelectDate(true);
            return NSToast.warn("Ce produit ne peut être commandé que le midi en semaine !");
        }

        if (product.type === 'simple') {
            CartLib.addToCart(product, 1, (cart) => {
                window.localStorage.setItem('cart_id', cart._id);
                NSToast.success('Produit ajouté !');
                const event = new CustomEvent('updateCart', { detail: { cart } });
                window.dispatchEvent(event);
            }, (err) => {
                axios.post(`${getAPIUrl()}v2/mail/error`, err.toString());
                NSToast.error('Produit indisponible');
            });
        } else {
            const compositionMenu = {
                cartId : '',
                item   : {
                    _id        : product._id,
                    id         : product._id,
                    quantity   : 1,
                    selections : []
                }
            };
            try {
                for (let i = 0; i < product.bundle_sections.length; i++) {
                    const cm = this.createMenuSection(product.bundle_sections[i], this.formMenu[product.bundle_sections[i].ref]);
                    if (cm.products.length > 0) { compositionMenu.item.selections.push(cm); }
                }
            } catch (e) {
                console.error(e);
                NSToast.warn(e);
                return;
            }
            compositionMenu.cartId = window.localStorage.getItem('cart_id');
            CartLib.addToCartBundleProduct(compositionMenu, (cart) => {
                NSToast.success('Produit ajouté !');
                this.onCloseModalEditMenu();
                window.localStorage.setItem('cart_id', cart._id);
                const event = new CustomEvent('updateCart', { detail: { cart } });
                window.dispatchEvent(event);
            }, (err) => {
                axios.post(`${getAPIUrl()}v2/mail/error`, err.toString());
                NSToast.error('Produit indisponible');
            });
        }
    };

    openModalEditMenu = async (e) => {
        e.preventDefault();
        const { setOpenModalSelectDate } = this.props;

        const now = new Date();
        let verifDate = new Date();
        const cartId = window.localStorage.getItem('cart_id');
        if (cartId) {
            // Requête pour sélectionner le panier (la route v2 permet de populate les données produits pour les menus)
            const resCart = await axios.post(`${getAPIUrl()}v2/cart/${cartId}`, { lang: 'fr' });
            if (resCart.status !== 500 && resCart.data && resCart.data.orderReceipt.date) {
                verifDate = resCart.data.orderReceipt.date;
            }
        }

        // EXCEPTION EN DUR validée par Bertrand et Vincent
        // Les formules midi ne sont commandables que le matin
        const { product, exceptions } = this.state;
        if ((Number(moment(verifDate).format('HHmm')) >= 1400 || Number(moment(verifDate).format('d')) === 0 || Number(moment(verifDate).format('d')) === 6) && exceptions.includes(product.code)) {
            setOpenModalSelectDate(true);
            return NSToast.warn("Ce produit ne peut être commandé que le midi en semaine !");
        }

        this.setState({ openModalEditMenu: true });
    };

    onCloseModalEditMenu = () => {
        this.setState({ openModalEditMenu: false });
    };

    getImage = (img) => {
        if (img._id) {
            const filename = img.url.split('/')[img.url.split('/').length - 1];
            return `images/products/max-70/${img._id}/${filename}`;
        }
        return img.url;
    }

    render() {
        const { category } = this.props;
        const { openModalEditMenu, product } = this.state;

        // Image
        const img = product.images && product.images.length > 0 ? product.images.find((i) => i.default === true) || product.images.sort((a, b) => a.position - b.position)[0] : { url: 'medias/no-image.png' };

        // Prix
        const price = product.price && product.price.ati.normal && product.price.ati.normal ? product.price.ati.normal.toFixed(2) : '0.00';
        const price_special = product.price && product.price.ati.special && product.price.ati.special ? product.price.ati.special.toFixed(2) : false;

        return (
            <>
                <div className="col-12 col-md-4">
                    <Link route="product" params={{ _slug: category.slug.fr, product: product.slug.fr }}>
                        <a className="item">
                            <div className="item__thumb">
                                <img src={`/${this.getImage(img)}`} alt={img.alt} />
                            </div>
                            <div className="item__link">
                                <span className="btn btn-add" onClick={product.type === 'simple' ? this.addToCart : this.openModalEditMenu}><i className="icon-add" /></span>
                            </div>
                            <div className="item__info">
                                <h2 className="item__category">{category.name.toUpperCase()}</h2>
                                <h3 className="item__name">{product.name}</h3>
                                <p className="item__desc">{product.description2 && product.description2.title}</p>
                                <p className="item__price">
                                    {price_special && <small className="item__price__promo">{price} €</small>} {price_special || price} €
                                </p>
                            </div>
                        </a>
                    </Link>
                </div>
                {
                    product.type !== 'simple' && (
                        <ModalR
                            animationDuration={0} classNames={{ modal: 'modifier-popup active', overlay: 'popup active' }}
                            open={openModalEditMenu} onClose={this.onCloseModalEditMenu} center showCloseIcon={false}
                        >
                            <div className="modifier-popup__wrap">
                                <button type="button" className="btn btn-link modifier-popup__close" onClick={this.onCloseModalEditMenu} />
                                <h3 className="modifier-popup__header">COMPOSEZ VOTRE MENU</h3>
                                <form ref={(form) => this.formMenu = form}>
                                    <div className="form__body">
                                        <MenuProduct product={product} />

                                        <div className="price">
                                            {(product.price.ati.normal || 0).toFixed(2)} €
                                        </div>{/* <!-- /.price --> */}
                                    </div>{/* <!-- /.popup__body --> */}
                                    <div className="form-footer">
                                        <button type="button" className="btn btn-primary" onClick={this.addToCart}>AJOUTER AU PANIER</button>
                                    </div>
                                </form>
                            </div>
                        </ModalR>
                    )
                }
            </>
        );
    }

    static defaultProps = {
        product : {
            _id          : '',
            images       : [],
            name         : 'Undefined',
            description2 : { title: '', text: '' }
        }
    };

    static contextType = NSContext;
}

export default withI18next(['product-card'])(ProductCard);
