import React from 'react';
import { NSContext } from 'aqlrc';
import { withI18next } from '../lib/withI18n';
import { Link } from '../routes';

/*
Ce composant affiche le menu en fonction du mode qu'on lui passe en props :
- "left" : menu de gauche sous forme de liste
- "grid" : menu en grille avec les images
*/

class MultiMenu extends React.Component {
    render() {
        const { category, mode } = this.props;
        const { menu } = this.context.props;
        return (
            mode === 'left' ? (
                <>
                    <ul className="sidebar__menu">
                        {
                            menu.map((child, index) => (
                                <li className={category && category.slug.fr === child.slug.fr ? 'active' : ''} key={index}>
                                    <Link route="categoryI18n" params={{ _slug: child.slug.fr }}><a style={child.code === 'nouveautes' ? { color: 'red' } : null}>{child.name.toUpperCase()}</a></Link>
                                </li>
                            ))
                        }
                    </ul>
                    <Link route="staticI18n" params={{ _slug: 'liste-allergenes' }}>
                        <a className="btn btn-primary">
                            <i className="icon-sidebar-btn" />
                            <span>Allergènes</span>
                        </a>
                    </Link>
                </>
            ) : (
                menu.filter((m) => m.code !== 'nouveautes').map((child, index) => (
                    <div className="grid-view__item" key={index}>
                        <Link route="categoryI18n" params={{ _slug: child.slug.fr }}>
                            <a className="item">
                                <div className="item__body">
                                    <img className="item__thumb" src={`/medias/${child.slug.fr}.png`} alt="" />
                                    <h4 className="item__name">{child.name.toUpperCase()}</h4>
                                </div>
                            </a>
                        </Link>
                    </div> /* <!-- /.grid-view__item --> */
                ))
            )
        );
    }

    static defaultProps= {
        category : ''
    };

    static contextType = NSContext;
}

export default withI18next([])(MultiMenu);
