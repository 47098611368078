import { Component } from 'react';
import axios from 'axios';
import {
    generateAPIUrl as getAPIUrl, NSToast
} from 'aqt-base-components';
import {NSContext} from 'aqlrc'
import PropTypes from 'prop-types';

class ModuleFoodOptions extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            ...props,
            itemsFoodOptions : [],
            saucesOffered: 0,
            linkNames: {},
            links: []
        };
        
    }

    getLinks = async function() {
        if (this.state.links.length === 0) {
            const res = await axios.get(`${getAPIUrl()}v2/food-options/getLinks`);
            this.setState({links: res.data.links, linkNames: res.data.linkNames});
        }
    }

    calculateFoodOptions = () => {
        if(this.state.cart) {
            const { items = [] } = this.state.cart;
            const itemsFoodOptions = [];
            for (let j = 0; j < items.length; j++) {
                if (items[j].foodOptionFree) {
                    itemsFoodOptions[j] = {
                        ...items[j],
                        quantity : items[j].quantity,
                        price    : {
                            unit  : { ati: 0, et: 0 },
                            total : { ati: 0 }
                        }
                    };
                    if (items.find(i => i.code === items[j].code && !i.foodOptionFree)) {
                        const itemPaid = { ...items.find(i => i.code === items[j].code && !i.foodOptionFree) };
                        itemsFoodOptions[j].quantity += itemPaid.quantity;
                        itemsFoodOptions[j].price.unit.ati += itemPaid.price.unit.ati;
                        itemsFoodOptions[j].price.unit.et += itemPaid.price.unit.et;
                        itemsFoodOptions[j].price.total.ati += itemPaid.price.total.ati;
                    }
                }
            }
            return itemsFoodOptions.sort((a, b) => a.weight - b.weight);
        }
        return []
    }

    async componentDidMount() {
        await this.getLinks();
        if(window.localStorage.getItem('cart_id')) {
            const cart = await axios.post(`${getAPIUrl()}v2/cart/${window.localStorage.getItem('cart_id')}`, {PostBody: {filter: {_id: window.localStorage.getItem('cart_id')}, populate: ['items.id'], structure: {'items.id': 1}}})
            if(cart.data) {
                this.setState({cart: cart.data}, async () => this.setState({saucesOffered: await this.calculateSaucesOffered()}))
            } else {
                window.localStorage.removeItem('cart_id')
            }
        } 
        window.addEventListener('updateCart', (e) => {
            this.setState({cart: e.detail.cart}, async () => this.setState({saucesOffered: await this.calculateSaucesOffered()}))
        })
        
    }

    changeQty = async (addOrRemove, item) => {
        const { updateItems } = this.props;
        try {
            if (addOrRemove === '-' && item.quantity > 0) {
                item.quantity--;
            } else if (addOrRemove === '+') {
                item.quantity++;
            } else {
                return;
            }
            const response = await axios.put(`${getAPIUrl()}v2/food-options/changeQty`, {
                item   : { _id: item._id, id: item.id._id, quantity: item.quantity },
                cartId : window.localStorage.getItem('cart_id')
            });

            // Requête pour sélectionner le panier (la route v2 permet de populate les données produits pour les menus)
            const resCart = await axios.post(`${getAPIUrl()}v2/cart/${response.data._id}`, {
                lang     : this.context.props.i18n.language,
                PostBody : {
                    structure : {
                        comment : 1
                    },
                    populate      : ['items.id'],
                    populateItems : true
                }
            });
            if (resCart.status === 500) {
                NSToast.error('common:error_occured', this.context.props.i18n);
                return;
            }
            const event = new CustomEvent('updateCart', {detail: {cart: resCart.data}})
            window.dispatchEvent(event)
            this.setState({cart: resCart.data}, async () => this.setState({saucesOffered: await this.calculateSaucesOffered()}))
        } catch (error) {
            console.error('Food Options : ', error);
            // Si il y a une erreur on remet la quantity a l'état initial
            if (addOrRemove === '-') item.quantity++;
            else if (addOrRemove === '+') item.quantity--;
        }
    };

    calculateSaucesOffered = async () => {
        const itemsNoFood = this.state.cart.items.filter((f) => !f.foodOption);
        const productsFoodOption = await axios.post(`${getAPIUrl()}v2/products`, {PostBody: {filter: {foodOption: true}, limit: 99, structure: {code: 1}}})
        let saucesOffered = 0;
        for (let i = 0; i < itemsNoFood.length; i++) {
            for(let j = 0; j < productsFoodOption.data.datas.length; j++) {
                const value = itemsNoFood[i].id.attributes.find((a) => a.code === productsFoodOption.data.datas[j].code) && itemsNoFood[i].id.attributes.find((a) => a.code === productsFoodOption.data.datas[j].code).value ? itemsNoFood[i].id.attributes.find((a) => a.code === productsFoodOption.data.datas[j].code).value * itemsNoFood[i].quantity : 0;
                saucesOffered += value;
            }
        }
        return Math.ceil(saucesOffered);
    }

    render()  {
        if(!this.state.cart) return (
            <>
                <h2>PRODUITS COMPLEMENTAIRES</h2>
                <table className="table__group">
                    <tbody>
                        <tr style={{ textAlign: 'center' }}><td colSpan="3">Pas de produits supplémentaires</td></tr>
                    </tbody>
                </table>
            </>
        )
        const itemsFoodOptions = this.calculateFoodOptions();
        console.log(`${this.state.saucesOffered} ${this.state.linkNames['0']}`);
        return (
            <>
                <h2>PRODUITS COMPLEMENTAIRES</h2>
                <table className="table__group">
                    <tbody>
                        {
                            !itemsFoodOptions || itemsFoodOptions.length === 0
                                ? <tr style={{ textAlign: 'center' }}><td colSpan="3">Pas de produits supplémentaires</td></tr>
                                : itemsFoodOptions.map((item, index) => (
                                    <tr  key={item._id} className={`${index === 0 ? 'link-top' : (index === 1 ? 'link-bottom' : null)}`}  data-label={index === 0 ? `${this.state.saucesOffered} ${this.state.linkNames[index]}` : null} >
                                        <td className="image-col">
                                            <div className="cart__image">
                                                {item.id.images.find(img => img.default) && <img src={`/images/products/150x90-30/${item.id.images.find(img => img.default)._id}/${item.code}.png`} alt="" />}
                                            </div>
                                        </td>
                                        <td className="name-col">
                                            <div className="cart__name">
                                                <h3>{item.name}</h3>
                                            </div>
                                        </td>
                                        <td className="price-col">
                                            <div className="cart__item">
                                                <div className="cart__price">
                                                    <p>{(item.price.total.ati || 0).toFixed(2)}€</p>
                                                </div>
                                                <div className="cart__actions">
                                                    <button type="button" className="btn btn--cart" onClick={() => this.changeQty('-', item)}>-</button>
                                                    <div className="cart__quantity">{item.quantity}</div>
                                                    <button type="button" className="btn btn--cart" onClick={() => this.changeQty('+', item)}>+</button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                <p><b>Total TTC : {itemsFoodOptions && itemsFoodOptions.length > 0 ? itemsFoodOptions.map((i) => i.price.total.ati).reduce((a, b) => a + b).toFixed(2) : 0}€</b></p>
                <hr />
            <style jsx>{`
                /* === Cart === */
                    table {width: 100%; position: relative;}
                    table tr {padding: 10px;border-bottom: 1px solid #576fa1; width: 100%;height: 110px;}
                    table tr:last-child {border: 0;}
                    .name-col {width: 40%;}
                    .image-col {width: 30%;}
                    .price-col {width: 30%;}
                    .price-col .cart__actions {display: flex; justify-content: flex-end; vertical-align: middle;}
                    .price-col .cart__quantity {height: 38px; line-height: 38px;margin: 0 5px;}
                    .price-col .cart__price {text-align: right;}
                    table tr .btn {background-color: #576fa1; color: white;}
                    table tr .btn:hover {background-color: #576fa1D0; color: white;}

                .link-bottom:after {
                    content: '';
                    position: absolute;
                    width: 15px;
                    height: 100%;
                    bottom: -5px;
                    border: 1px solid  #576fa1;
                    border-left-width: 0;
                }
                
                .link-top:after {
                    position: absolute;
                    left: 100%;
                    top: 50%;
                    font-size: 0.70rem;
                    font-style: italic;
                    min-width: 60px;
                    color:  #576fa1;
                    display: -webkit-inline-box;
                    display: -webkit-inline-flex;
                    display: inline-flex;
                    content: attr(data-label) " ";
                    -webkit-box-align: center;
                    -webkit-align-items: center;
                    align-items: center;
                    transform: rotate(90deg) translate(-20%, -50%);
                    text-align: center;
                }
                
            `}</style>
            </>
        );
    }

    static defaultProps = {
        country : 'FR',
        saucesOffered: 0
    };

    static contextType = NSContext;
}

export default ModuleFoodOptions;