import React from 'react';
import dynamic from 'next/dynamic';
import { NSContext, NSGallery } from 'aqlrc';
import { withI18next } from '../lib/withI18n';

class Gallery extends NSGallery {
    render() {
        const maxWidthGallery = 1440;
        const { photoIndex, isOpen } = this.state;
        const code = this.props['ns-code'];
        let nsGallery;
        if (!this.state.nsGallery) nsGallery = this.context.state[`nsGallery_${code}`];
        else nsGallery = this.state.nsGallery;
        if (!nsGallery) {
            console.error("Impossible d'afficher le composant Gallery, veuillez verifier son ns-code", this.props['ns-code']);
            return <div>Impossible d'afficher le composant Gallery, veuillez verifier son ns-code</div>;
        }
        const { datas: imageOrVideos, maxColumnNumber } = nsGallery;
        const imgVidUrl = imageOrVideos.map((oImgOrVid) => {
            if (oImgOrVid.content) return { content: <Video content={oImgOrVid.content} />, alt: oImgOrVid.alt };
            return { content: `/${oImgOrVid.srcset[0]}`, alt: oImgOrVid.alt };
        });
        let Lightbox;
        if (typeof window !== 'undefined') Lightbox = dynamic(import('lightbox-react'));
        return (
            <div className="NSGallery">
                {typeof window !== 'undefined' && isOpen
                    && (
                        <Lightbox
                            mainSrc={imgVidUrl[photoIndex].content} // On recupére la 1ere img du srcset qui sera l'image taille réelle, src sera le thumbnail
                            nextSrc={imgVidUrl[(photoIndex + 1) % imgVidUrl.length].content}
                            prevSrc={imgVidUrl[(photoIndex + imgVidUrl.length - 1) % imgVidUrl.length].content}
                            imageTitle={imgVidUrl[photoIndex].alt}
                            onCloseRequest={() => this.setState({ isOpen: false })}
                            onMovePrevRequest={() => this.setState({ photoIndex: (photoIndex + imgVidUrl.length - 1) % imgVidUrl.length })}
                            onMoveNextRequest={() => this.setState({ photoIndex: (photoIndex + 1) % imgVidUrl.length })}
                        />
                    )}
                <div className="gallery-grid">
                    {
                        imageOrVideos.map((oImgOrVid, i) => (
                            <div key={i} className="grid-item" onClick={() => this.openLightBox(i)}>
                                <div className="overlay">
                                    <div className="text">{oImgOrVid.alt}</div>
                                </div>
                                {oImgOrVid.content
                                    ? (
                                        <div className="videoPlay">
                                            <img className="imgPlay" src="../static/playbtn.png" />
                                            <img src={`https://img.youtube.com/vi/${oImgOrVid.content}/0.jpg`} />
                                        </div>
                                    )
                                    : <img src={`/${oImgOrVid.src}`} alt={oImgOrVid.alt} /* srcSet={oImgOrVid.srcset.toString()} */ />}
                            </div>
                        ))
                    }
                </div>
                <div className="load-more">
                    {this.state.btnActif && (nsGallery.datas.length !== nsGallery.count) ? <button onClick={this.loadData}>+</button> : ''}

                </div>
                <style jsx="true">{`
                .NSGallery{
                    font-family: 'Oswald', sans-serif;
                }
                .text {
                    color: white;
                    font-size: 20px;
                    position: absolute;
                    padding-bottom: 20px;
                    padding-top: 20px;
                    top: 50%;
                    left: 50%;
                    display: flex;
                    width: 33%;
                    justify-content: center;
                    transform: translate(-50%,-50%);
                    border-bottom: 1px solid white;
                    border-top: 1px solid white;
                  }
                .overlay:hover{
                    opacity: 0.8;
                }
                .overlay {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 100%;
                    width: 100%;
                    opacity: 0;
                    transition: .5s ease;
                    background-color: #000000;
                    z-index:100;
                    font-size: 20px;
                    color: #FFFFFF;
                  }


                .gallery-grid {
                    display: grid;
                    grid-gap: 36px;                    
                    grid-template-columns: repeat(${maxColumnNumber}, minmax(100px,1fr));
                    justify-items: center;
                    position: relative;
                  }
                .grid-item img{
                    /*min-width:100px;
                    max-width:354px;
                    min-height:100px;
                    max-height:354px;
                    width: 100%;
                    height: 100%;*/
                    width:354px;
                    height:354px;
                    border: 10px solid #FFFFFF;
                    object-fit: cover;
                }
                .grid-item{
                    display: grid;
                    cursor:pointer;
                    transition:0.4s;
                    position: relative;
                }                
                .videoPlay .imgPlay{
                    position: absolute; 
                    padding:125px;   
                    border:none;     
                }
                .load-more{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                }
                .load-more button{
                    background-color: rgb(253, 188, 88);
                    border:none;
                    color: white;
                    font-size: 28px;
                    margin-top: 60px;
                    margin-bottom: 81px;
                    width: 120px;
                    transition:0.2s;
                    cursor:pointer;
                }
                .load-more button:hover{
                    background-color: rgb(251, 176, 61);
                }
                @media screen and (max-width:${maxWidthGallery}px){
                    .gallery-grid {
                        display: grid;
                        grid-gap: 36px;
                        grid-template-columns: repeat(auto-fill, minmax(354px,1fr));
                        position: relative;
                      }
                      .grid-item img{
                        width: 354px;
                        height: 354px;
                        border: 10px solid #FFFFFF;
                        object-fit: cover;
                    }
                }
            `}</style>
            </div>
        );
    }

    static contextType = NSContext;
}

export default withI18next(['common'])(Gallery);
