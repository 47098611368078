import React from 'react';
import { NSCartProductCounter } from 'aqlrc';
import { withI18next } from '../lib/withI18n';
import { Link } from '../routes';


class CartProductCounter extends NSCartProductCounter {
    render() {
        const { cart } = this.state;
        return (
            <li className="cart-product-counter">
                <Link route="cart">
                    <a data-label={cart && cart.items && cart.items.filter((i) => !i.foodOption).length > 0 ? cart.items.filter((i) => !i.foodOption).length : ''}>
                        <i className="icon-shopping" />
                        <span>Mon panier</span>
                    </a>
                </Link>
            </li>
        );
    }
}

export default withI18next(['common'])(CartProductCounter);
