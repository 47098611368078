import React from 'react';
import axios from 'axios';
import Head from 'next/head';
import moment from 'moment';
import ModalR from 'react-responsive-modal';
import Geosuggest from 'react-geosuggest';
import DatePicker, { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr'; // Pour mettre le datepicker en français
import { NSToast, getAPIUrl, jwtManager } from 'aqlrc';
import { Router } from '../../routes';
import { withI18next } from '../../lib/withI18n';

/**
 * @function getArraySchedules
 * @param  {type} currentPOS   PointOfSale actuellement selectionné
 * @param  {type} date         Date de livraison
 * @param  {type} delay        Délai de préparation en minutes
 * @return {type} Retourne le tableau global des horaires
 */
function getArraySchedules(currentPOS, date, delay = 30) {
    const nowTimestamp = Math.trunc(Date.now() / 1000);
    const selDate      = new Date(date);
    const selDay       = selDate.getDay() === 0 ? 6 : selDate.getDay() - 1;
    const timeLine     = currentPOS.deliveryAvailability[selDay]; // Horaires du jour sélectionné
    const step         = currentPOS.timeSlot ? Number(currentPOS.timeSlot) : 30; // Créneau horaire (en minutes)

    const array = [];
    if (timeLine) {
        const year  = selDate.getFullYear(); // Année de la date sélectionnée
        const month = selDate.getMonth(); // Mois de la date sélectionnée
        const day   = selDate.getDate(); // Jour de la date sélectionnée

        // On détermine le nombre de commandes par horaire du jour sélectionné
        const orders = {};
        if (currentPOS.orders && currentPOS.orders.length) {
            for (let i = 0; i < currentPOS.orders.length; i++) {
                const order      = currentPOS.orders[i];
                const date_order = new Date(order.date);
                if (`${year}-${month}-${day}` === `${date_order.getFullYear()}-${date_order.getMonth()}-${date_order.getDate()}`) {
                    const hour    = (`0${date_order.getHours()}`).substr(-2);
                    const minute  = (`0${date_order.getMinutes()}`).substr(-2);
                    const index   = `${hour}h${minute}`;
                    orders[index] = orders[index] ? orders[index] + 1 : 1;
                }
            }
        }

        // On boucle sur les 2 créneaux
        for (let i = 1; i <= 2; i++) {
            const min = timeLine[`minHour${i}`];
            const max = timeLine[`maxHour${i}`];
            if (min && max) {
                const [minHour, minMinute] = min.split('h');
                const minTimestamp         = Math.trunc(new Date(year, month, day, minHour, minMinute, 0).getTime() / 1000); // Timestamp min

                const [maxHour, maxMinute] = max.split('h');
                const maxTimestamp         = Math.trunc(new Date(year, month, day, maxHour, maxMinute, 0).getTime() / 1000); // Timestamp max

                // On détermine les horaires en fonction du min et du max
                let t = minTimestamp;
                while (t <= maxTimestamp) {
                    if (t >= nowTimestamp + (currentPOS.prepareDelay * 60)) {
                        const hour   = (`0${new Date(t * 1000).getHours()}`).substr(-2);
                        const minute = (`0${new Date(t * 1000).getMinutes()}`).substr(-2);
                        const slot   = `${hour}h${minute}`;

                        // Si l'horaire n'est pas complet, on l'ajoute
                        if (!orders[slot] || (orders[slot] && orders[slot] < currentPOS.maxOrdersPerSlot)) {
                            array.push(slot);
                        }
                    }
                    t += step * 60; // On augmente en fonction de l'intervalle passé en paramètre
                }
            }
        }
    }

    return array;
}

function convertGeoSuggest(suggest) {
    if (suggest === undefined || suggest.gmaps === undefined) return;
    const delivery = {
        street_number  : '',
        route          : '',
        locality       : '',
        postal_code    : '',
        country        : '',
        initialAddress : undefined,
    };
    for (let i = 0; i < suggest.gmaps.address_components.length; i++) {
        const valueType = suggest.gmaps.address_components[i].types[0];
        const valueLong = suggest.gmaps.address_components[i].long_name;
        const valueShort = suggest.gmaps.address_components[i].short_name;
        delivery[valueType] = valueLong;
        if (valueType === 'country') {
            delivery.isoCountryCode = valueShort;
        }
    }
    return {
        line1          : delivery.street_number + (delivery.route ? ` ${delivery.route}` : ''),
        zipcode        : delivery.postal_code,
        city           : delivery.locality,
        isoCountryCode : delivery.isoCountryCode,
        country        : delivery.country,
    };
}

function toAquilaAddress(address) {
    if(address.gmaps.address_components[0].types.includes("street_number")) {
        return {
            city           : address.gmaps.address_components[2] ? address.gmaps.address_components[2].long_name : '',
            country        : address.gmaps.address_components[5] ? address.gmaps.address_components[5].long_name : '',
            line1          : address.description.split(', ')[0],
            isoCountryCode : address.gmaps.address_components[5] ? address.gmaps.address_components[5].short_name : '',
            zipcode        : address.gmaps.address_components[6] ? address.gmaps.address_components[6].long_name : '',
        };
    } else {
        return {
            city           : address.gmaps.address_components[1] ? address.gmaps.address_components[1].long_name : '',
            country        : address.gmaps.address_components[4] ? address.gmaps.address_components[4].long_name : '',
            line1          : address.description.split(', ')[0],
            isoCountryCode : address.gmaps.address_components[4] ? address.gmaps.address_components[4].short_name : '',
            zipcode        : address.gmaps.address_components[5] ? address.gmaps.address_components[5].long_name : '',
        };
    }
}

class SelectDate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            address             : '',
            arrayPOS            : [],
            arraySchedules      : [],
            currentPOS          : null,
            dateShop            : new Date(),
            gMapKey             : '',
            hasWithdrawal       : 0,
            hasDelivery         : 0,
            homeDelivery        : false,
            initialAddress      : '',
            isValidAddress      : false,
            openModalSelectDate : false,
            popupCurrentPOS     : null,
            popupDateShop       : new Date(),
            popupHomeDelivery   : false,
            popupTimeShop       : '',
            timeShop            : '',
        };
    }

    async componentDidMount() {
        registerLocale('fr', fr); // Pour mettre le datepicker en français
        
        let { currentPOS, hasDelivery, hasWithdrawal, homeDelivery, popupHomeDelivery } = this.state;
        const res = await axios.get(`${getAPIUrl()}pointOfSale/getKey`);
        const gMapKey = res.data.api_key_google_map;
        const pointsOfSale = await axios.post(`${getAPIUrl()}v2/pointsOfSale/populate`, { PostBody: { filter: {}, limit: 999999 }});
        const arrayPOS = pointsOfSale.data.filter(pos => pos.isWithdrawal || pos.isDelivery);
        for (const pos of arrayPOS) {
            if (pos.isWithdrawal) hasWithdrawal = 1;
            if (pos.isDelivery) hasDelivery = 1;
        }
        if (!hasWithdrawal && hasDelivery) {
            homeDelivery = 1;
            popupHomeDelivery = 1;
        }
        if (hasWithdrawal) { currentPOS = arrayPOS.filter(pos => pos.isWithdrawal)[0]; }

        if (window.localStorage.getItem('cart_id')) {
            const result = await axios.post(`${getAPIUrl()}v2/cart/${window.localStorage.getItem('cart_id')}`, { lang: 'fr', PostBody: { structure: { point_of_sale: 1 }, populate: ['items.id'] } });
            if (arrayPOS.find((pos) => pos._id === result.data.point_of_sale)) {
                currentPOS = arrayPOS.find((pos) => pos._id === result.data.point_of_sale);
            }
            if (result.data.orderReceipt.date !== undefined) {
                const dateShop = result.data.orderReceipt.date ? new Date(result.data.orderReceipt.date) : new Date();
                const timeShop = result.data.orderReceipt.date ? moment(new Date(result.data.orderReceipt.date)).format('HH[h]mm') : '';

                return this.setState({
                    arrayPOS,
                    currentPOS,
                    dateShop,
                    gMapKey,
                    hasDelivery,
                    hasWithdrawal,
                    homeDelivery     : result.data.orderReceipt.method === 'delivery',
                    initialAddress   : result.data.orderReceipt.method === 'delivery' && result.data.addresses && result.data.addresses.delivery ? ([result.data.addresses.delivery.line1, result.data.addresses.delivery.city, result.data.addresses.delivery.country]).join(', ') : '',
                    isValidAddress   : result.data.orderReceipt.method === 'delivery' && result.data.addresses !== undefined && result.data.addresses.delivery !== undefined,
                    popupCurrentPOS  : currentPOS,
                    popupDateShop    : dateShop,
                    popupHomeDelivery: result.data.orderReceipt.method === 'delivery',
                    popupTimeShop    : timeShop,
                    timeShop,
                }, () => this.getSchedules('1'));
            }
        }
        this.setState({
            arrayPOS,
            popupCurrentPOS: currentPOS,
            currentPOS,
            gMapKey,
            hasDelivery,
            hasWithdrawal,
            homeDelivery,
            popupHomeDelivery
        }, () => this.getSchedules());
    }

    onSuggestSelect = async (suggest) => {
        const delivery_address = convertGeoSuggest(suggest);
        if (!delivery_address) {
            return this.setState({ isValidAddress: false })
        }
        if (!delivery_address.zipcode || !delivery_address.line1 || !delivery_address.city) {
            return this.setState({ isValidAddress: false }, () => NSToast.warn('Merci de renseigner une adresse plus précise'));
        }
        // On vérifie si un point de vente peut livrer cette adresse
        try {
            const result = await axios.post(`${getAPIUrl()}pointsOfSale/findForDelivery`, delivery_address);
            if (result.data.code === 'CAN_BE_DELIVERED') {
                this.setState({
                    address    : suggest,
                    initialAddress : ([delivery_address.line1, delivery_address.city, delivery_address.country]).join(', '),
                    isValidAddress : true,
                    popupCurrentPOS: result.data.data,
                }, () => this.getSchedules());
            } else {
                this.setState({
                    popupCurrentPOS: null,
                    isValidAddress : false,
                    initialAddress : ([delivery_address.line1, delivery_address.city, delivery_address.country]).join(', ')
                });
                NSToast.warn('Cette adresse ne peut pas être livrée.');
            }
        } catch (error) {
            console.error(error);
            NSToast.warn('Adresse invalide');
            this.setState({ isValidAddress: false });
        }
    }

    getSchedules = (submit = '0') => {
        const {
            popupCurrentPOS, popupDateShop, popupTimeShop
        } = this.state;
        if (!popupCurrentPOS) return;
        if (moment().add(popupCurrentPOS.prepareDelay, 'minutes').format('YYYYMMDDHm') > moment(popupDateShop).format('YYYYMMDDHm')) {
            const newDateShop = new Date();
            const arraySchedules = getArraySchedules(popupCurrentPOS, newDateShop);
            const ts = arraySchedules && arraySchedules[0] ? arraySchedules[0] : '';
            this.setState({
                popupDateShop: newDateShop,
                popupTimeShop: ts,
                arraySchedules
            }, submit === '1' ? () => this.submitShopReceipt('0') : () => {});
        } else {
            const arraySchedules = getArraySchedules(popupCurrentPOS, popupDateShop);
            const ts = arraySchedules && arraySchedules[0] ? (popupTimeShop !== '' && arraySchedules.includes(popupTimeShop) ? popupTimeShop : arraySchedules[0]) : '';
            this.setState({
                popupTimeShop     : ts,
                arraySchedules
            });
        }
    }

    submitShopReceipt = async (toast) => {
        const {
            address, isValidAddress, popupCurrentPOS, popupDateShop, popupHomeDelivery, popupTimeShop
        } = this.state;
        const cartId = window.localStorage.getItem('cart_id');
        if (popupHomeDelivery && isValidAddress) {
            const dateToSend = popupTimeShop.replace('h', ':');
            const body = {
                pointOfSale   : popupCurrentPOS,
                cartId,
                receiptDate   : new Date(`${moment(popupDateShop).format('MM/DD/YYYY')} ${dateToSend}`),
                receiptMethod : 'delivery',
                country       : 'FR',
                dateToSend
            };
            const response = await axios.put(`${getAPIUrl()}pointOfSale/orderReceipt`, body);
            if (response.data.data._id) {
                if (address.gmaps === undefined) {
                    this.setState({ currentPOS: popupCurrentPOS, dateShop: popupDateShop, homeDelivery: popupHomeDelivery, timeShop: popupTimeShop }, () => {
                        window.localStorage.setItem('cart_id', response.data.data._id)
                        this.onCloseModalSelectDate('0');
                    });
                    if (toast === '1') {
                        return NSToast.success('Informations enregistrées !');
                    }
                }
                const addresses = {
                    billing : toAquilaAddress(address),
                    delivery : toAquilaAddress(address)
                };
                const res = await axios.put(`${getAPIUrl()}v2/cart/addresses`, { cartId: response.data.data._id, addresses });
                if (res.status === 200) {
                    this.setState({ currentPOS: popupCurrentPOS, dateShop: popupDateShop, homeDelivery: popupHomeDelivery, timeShop: popupTimeShop }, () => {
                        window.localStorage.setItem('cart_id', response.data.data._id)
                        this.onCloseModalSelectDate('0');
                    });
                    if (toast === '1') {
                        NSToast.success('Informations enregistrées !');
                    }
                }
            }
        } else if (!popupHomeDelivery) {
            if (!popupTimeShop) { return NSToast.error('Retrait non disponible ce jour !'); }
            const dateToSend = popupTimeShop.replace('h', ':');
            const body = {
                pointOfSale   : popupCurrentPOS,
                cartId,
                receiptDate   : new Date(`${moment(popupDateShop).format('MM/DD/YYYY')} ${dateToSend}`),
                receiptMethod : 'withdrawal',
                dateToSend
            };
            const response = await axios.put(`${getAPIUrl()}pointOfSale/orderReceipt`, body);
            if (response.data.data._id) {
                const jwt = jwtManager.get();
                if (jwt) {
                    axios.defaults.headers.common.Authorization = jwt;
                }

                let user = jwtManager.getUser();
                if (user) {
                    const resUser = await axios.post(`${getAPIUrl()}v2/user/${user._id}`, {
                        PostBody : {
                            structure : { billing_address: 1, delivery_address: 1, addresses: 1 }
                        }
                    });
                    user = resUser.data;
                    const delivery = {
                        city           : popupCurrentPOS.address.city,
                        isoCountryCode : 'fr',
                        line1          : popupCurrentPOS.address.line1,
                        zipcode        : popupCurrentPOS.address.zipcode
                    };
                    const addresses = { billing: user.addresses[user.billing_address], delivery };
                    const res = await axios.put(`${getAPIUrl()}v2/cart/addresses`, { cartId: response.data.data._id, addresses });
                    if (res.status !== 200) {
                        return NSToast.error('L\'ajout d\'adresse a échoué');
                    }
                }
                this.setState({ currentPOS: popupCurrentPOS, dateShop: popupDateShop, homeDelivery: popupHomeDelivery, timeShop: popupTimeShop }, () => {
                    window.localStorage.setItem('cart_id', response.data.data._id);
                    this.onCloseModalSelectDate('0');
                });
                if (toast === '1') {
                    NSToast.success('Informations enregistrées !');
                }
            }
        }
        
    };

    openModalSelectDate = () => {
        const { currentPOS, dateShop, homeDelivery, timeShop } = this.state;
        this.setState({ openModalSelectDate: true, popupCurrentPOS: currentPOS, popupDateShop: dateShop, popupHomeDelivery: homeDelivery, popupTimeShop: timeShop }, () => this.getSchedules())
    };

    onCloseModalSelectDate = () => {
        this.setState({
            openModalSelectDate : false
        });
    };

    onChangePOS = (e) => {
        const { arrayPOS } = this.state;
        const currentPOS = arrayPOS.find(pos => pos._id === e.target.value);
        this.setState({ popupCurrentPOS: currentPOS }, () => this.getSchedules());
    }

    isInCheckout = () => {
        if (typeof window !== 'undefined') {
            return window.location.pathname.includes('/cart/');
        }
        return false;
    }

    render() {
        const { routerLang } = this.props;
        const {
            arrayPOS,
            arraySchedules,
            currentPOS,
            dateShop,
            gMapKey,
            hasDelivery,
            hasWithdrawal,
            homeDelivery,
            initialAddress,
            isValidAddress,
            popupCurrentPOS,
            popupDateShop,
            popupHomeDelivery,
            popupTimeShop,
            openModalSelectDate,
            timeShop
        } = this.state;
        return (
            <>
                <Head>
                    {gMapKey && <script src={`https://maps.googleapis.com/maps/api/js?key=${gMapKey}&libraries=places`} type="text/javascript"></script>}
                </Head>
                <div className="header__banner__wrap">
                    <div className="header__banner__info">
                        <div className="container">
                            <div className="row">
                                {
                                    hasWithdrawal || hasDelivery ? (
                                        <>
                                            <div className="col first">
                                                <div>
                                                    <img src="/images/medias/132x66-80/5fad60a7c4925c0600b6802a/Clickandcollect.png" alt="" />
                                                </div>
                                                <div className="header__banner__cartname">
                                                    <span><b>EN {homeDelivery ? 'LIVRAISON' : 'RETRAIT'} :</b> { homeDelivery ? initialAddress : currentPOS.name }</span><br />
                                                    <button type="button" className="btn btn--red" onClick={this.openModalSelectDate}>Changer</button>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="header__banner__title"><b> {homeDelivery ? 'Livraison prévue' : 'Retrait prévu'} :</b> {moment().format('L') === moment(dateShop).format('L') ? 'Aujourd\'hui' : moment(dateShop).format('DD/MM/YYYY')} {timeShop}</div>
                                                <button type="button" className="btn btn--red" onClick={this.openModalSelectDate}>Autre créneau</button>
                                            </div>
                                        </>
                                    ) : (
                                        <span>AUCUN POINT DE VENTE NE PROPOSE LE RETRAIT OU LA LIVRAISON</span>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.isInCheckout() && (
                        <div className="form__actions text-right" style={{ marginTop: '40px' }}>
                            <button
                                className="btn btn--grey"
                                style={{ float: 'left' }}
                                onClick={() => Router.pushRoute('cart', { lang: routerLang })}
                                type="button"
                            >
                                Retour
                            </button>
                            <button 
                                type="button"
                                className="form__btn btn btn--red"
                                disabled={!timeShop}
                                onClick={() => {
                                    this.submitShopReceipt('1');
                                    Router.pushRoute('cartPayment', { lang: routerLang });
                                }}
                            >Suivant</button>
                        </div>
                    )
                }
                <ModalR
                    animationDuration={0} classNames={{ modal: 'popup-selectDate' }}
                    open={openModalSelectDate} onClose={() => this.onCloseModalSelectDate('1')} center showCloseIcon={false}
                >
                    <div className="popup-content">
                        <button type="button" className="btn popup-close" onClick={() => this.onCloseModalSelectDate('1')}>X</button>
                        <h3 className="modifier-popup__header">INFORMATIONS DE {popupHomeDelivery ? 'LIVRAISON' : 'RETRAIT'}</h3>
                        <form>
                            <div className="form-group">
                                <div className="radio-group">
                                    {
                                        hasWithdrawal && hasDelivery ? (
                                            <>
                                                <div className="radio">
                                                    <input hidden type="radio" name="homeDelivery" id="radio-home" value={1} checked={popupHomeDelivery ? true : false} onChange={(e) => this.setState({ isValidAddress: false, popupHomeDelivery: Number(e.target.value) })} />
                                                    <label htmlFor="radio-home">LIVRAISON</label>
                                                </div>{/* <!-- /.radio --> */ }
                                                <div className="radio">
                                                    <input hidden type="radio" name="homeDelivery" id="radio-withdrawal" value={0} checked={!popupHomeDelivery ? true : false} onChange={(e) => this.setState({ popupCurrentPOS: arrayPOS.filter(pos => pos.isWithdrawal)[0], popupHomeDelivery: Number(e.target.value) }, () => this.getSchedules())} />
                                                    <label htmlFor="radio-withdrawal">RETRAIT</label>
                                                </div>{/* <!-- /.radio --> */ }
                                            </>
                                        ) : null
                                    }
                                </div>{/* <!-- /.radio-group --> */ }
                                {
                                    popupHomeDelivery ? (
                                        <>
                                            <label className="control-label" htmlFor="selectAddress">Adresse :</label>
                                            <Geosuggest
                                                placeholder=""
                                                inputClassName="field"
                                                initialValue={initialAddress}
                                                onSuggestSelect={this.onSuggestSelect}
                                                minLength={4}
                                                queryDelay={300}
                                                country="fr"
                                                types={['geocode']}
                                                onChange={(value) => this.setState({ initialAddress: value })}
                                                ref={(el) => this.input = el}
                                                required
                                                suggestItemActiveClassName="current"
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <label className="control-label" htmlFor="dateShop">Magasin :</label>
                                            {
                                                arrayPOS.length ? (
                                                    arrayPOS.length === 1 ? (
                                                        <span>{ arrayPOS[0].name }</span>
                                                    ) : (
                                                        <select className="field" value={popupCurrentPOS._id} onChange={this.onChangePOS}>
                                                            {
                                                                arrayPOS.filter(pos => pos.isWithdrawal).map(pos => {
                                                                    return (
                                                                        <option key={pos._id} value={pos._id}>{pos.name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    )
                                                ) : null
                                            }
                                        </>
                                    )
                                    
                                }
                                
                                <div hidden={(!isValidAddress && popupHomeDelivery)}>
                                    <label className="control-label" htmlFor="dateShop">Date et heure :</label>
                                    <div className="datetime">
                                        <div className="datepicker">
                                            <DatePicker
                                                minDate={new Date()}
                                                value={moment(popupDateShop).format('DD/MM/YYYY')}
                                                selected={new Date(popupDateShop)}
                                                locale="fr"
                                                onChange={(date) => this.setState({ popupDateShop: date }, () => this.getSchedules())}
                                                required
                                                id="dateShop"
                                                className="field"
                                            />
                                        </div>
                                        
                                        <select className="field" value={popupTimeShop} disabled={arraySchedules.length === 0} onChange={(e) => this.setState({ popupTimeShop: e.target.value })}>
                                            <option value="">{arraySchedules.length > 0 ? 'Heure de retrait' : 'Retrait non disponible ce jour' }</option>
                                            {
                                                arraySchedules.map((s) => <option key={s} value={s}>{s}</option>)
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="form-footer">
                                <button type="button" className="btn btn--red" disabled={!popupTimeShop} onClick={() => this.submitShopReceipt('1')}>Enregistrer</button>
                            </div>
                        </form>
                    </div>
                </ModalR>
                <style jsx>{`
                    .header__banner__wrap { padding-top: 2px; padding-bottom: 2px; margin-left: auto; margin-right: auto; border-top: 1px solid #9B9B9B; border-bottom: 1px solid #9B9B9B; }
                    .header__banner__info { max-width: 62rem; margin-left: auto; margin-right: auto; padding-top: 0.5rem; padding-bottom: 0.5rem;  }
                    .header__banner__info .row { display: -webkit-box; display: -webkit-flex; display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap; }
                    .header__banner__info .col { padding-left: 2rem; padding-right: 2rem; }
                    .header__banner__info .col.first { display: flex; }
                    .header__banner__info .col ~ .col { border-left: 1px solid #9B9B9B; }
                    .header__banner__cartname { margin-left: 20px; }
                    
                    @media (max-width: 767px) {
                        .header__banner__info .row { flex-direction: column; }
                        .header__banner__info .col.first { flex-direction: column; }
                        .header__banner__info .col ~ .col { border-left: none; }
                        .header__banner__cartname { margin-left: 0px; }
                    }
                `}</style>
                <style jsx global>{`
                    .popup-selectDate .control-label { display: block; font-weight: bold; }
                    .popup-selectDate .radio-group { display: flex; justify-content: space-between; }
                    
                    .popup-selectDate .geosuggest {
                        position: relative;
                    }
                    .popup-selectDate .geosuggest ul {
                        border: 1px solid #9B9B9B;
                        border-top: none;
                        list-style: none;
                        position: absolute;
                        margin-top: -10px;
                        left: 0;
                        right: 0;
                        z-index: 9;
                    }
                    .popup-selectDate .geosuggest ul li {
                        border-bottom: 1px solid #ccc;
                        line-height: 40px;
                        padding: 0 15px;
                        background-color: #fff;
                    }
                    .popup-selectDate .geosuggest ul li:hover, 
                    .popup-selectDate .geosuggest ul li.current {
                        background: #eee;
                        cursor: pointer;
                    }
                    .popup-selectDate .geosuggest__suggests--hidden {
                        max-height: 0;
                        overflow: hidden;
                        border-width: 0;
                    }
                    
                    .popup-selectDate .datetime { display: flex; }
                    .popup-selectDate .datetime .datepicker { width: 49%; padding-right: 2%; }
                    .popup-selectDate .datetime select { width: 49%; }
                    .popup-selectDate .popup-content { padding: 20px 30px; }
                    .popup-selectDate .popup-close {
                        position: absolute;
                        font-size: 20px;
                        color: #9B9B9B;
                        top: 10px;
                        right: 0.75rem;
                        width: 36px;
                        height: 36px;
                        padding: 0;
                        display: inline-block;
                    }
                    
                    .popup-selectDate .field { margin-bottom: 10px; }
                `}</style>
            </>
        );
    }
}

export default withI18next([])(SelectDate);
