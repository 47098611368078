import React from 'react';
import { NSButtonLoginAccount, NSContext } from 'aqlrc';
import { withI18next } from '../lib/withI18n';
import { Link } from '../routes';

/*
Ce composant affiche le bouton "Mon compte" avec un lien vers la page "auth" si l'utilisateur n'est pas connecté ou "account" dans le cas contraire
*/

class ButtonLoginAccount extends NSButtonLoginAccount {
    render() {
        const { user } = this.context.state;
        return (
            <li>
                <Link route={user ? 'account' : 'auth'}>
                    <a>
                        <i className="icon-user" />
                        <span>Mon compte</span>
                    </a>
                </Link>
            </li>
        );
    }

    static contextType = NSContext;
}

export default withI18next([])(ButtonLoginAccount);
