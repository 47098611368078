import React from 'react';
import axios from 'axios';
import {
    NSContext, NSCookieBanner, NSToast, scrollToTop
} from 'aqlrc';
import { withRouter } from 'next/router';
import PropTypes from 'prop-types';
import { Router } from '../routes';
import { withI18next } from '../lib/withI18n';
import CMS from './CMS';

/*
Composant qui englobe les pages avec le header et le footer
Sont ajoutés également les composants qui gèrent les toasts et la barre pour accepter ou non les cookies)
*/

class Layout extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            messageCookie : context.state ? context.state.messageCookie : ''
        };
    }

    componentDidMount = () => {
        Router.onRouteChangeComplete = () => {
            if (typeof window !== 'undefined' && window.location.hash === '') scrollToTop(1000);
        };
        axios.interceptors.request.use((config) => {
            // spinning start to show
            // UPDATE: Add this code to show global loading indicator
            if (typeof window !== 'undefined') {
                const requestSent = new window.CustomEvent('requestSent', { detail: {} });
                window.dispatchEvent(requestSent);
            }
            return config;
        }, (error) => {
            if (typeof window !== 'undefined') {
                const requestSent = new window.CustomEvent('requestSent', { detail: {} });
                window.dispatchEvent(requestSent);
            }
            Promise.reject(error);
        });

        axios.interceptors.response.use((response) => {
            // spinning hide
            // UPDATE: Add this code to hide global loading indicator
            if (typeof window !== 'undefined') {
                const responseReceive = new window.CustomEvent('responseReceive', { detail: {} });
                window.dispatchEvent(responseReceive);
            }
            return response;
        }, (error) => {
            if (typeof window !== 'undefined') {
                const responseReceive = new window.CustomEvent('responseReceive', { detail: {} });
                window.dispatchEvent(responseReceive);
            }
            if (error && error.response && error.response.data && error.response.data.message) {
                if (error.response.status < 300) {
                    NSToast.success(error.response.data.message);
                } else if (error.response.status < 400) {
                    NSToast.warn(error.response.data.message);
                } else {
                    switch (error.response.data.code) {
                    case 'invalid_objectid_error':
                        if (typeof window !== 'undefined') {
                            window.localStorage.removeItem('cart_id');
                        }
                        break;
                    case 'NOT_FOUND':
                        if (typeof window !== 'undefined') {
                            window.localStorage.removeItem('cart_id');
                        }
                        break;
                    default:
                        NSToast.error(error.response.data.message);
                    }
                }
            }
            return Promise.reject(error);
        });
    }

    render() {
        const {
            bodyClass, header, children, footer
        } = this.props;
        const { messageCookie } = this.context.state;
        return (
            <div className={bodyClass}>
                <NSToast />
                <CMS content={header} />
                {children}
                <CMS content={footer} />
                {/* <NSCookieBanner message={messageCookie} button="Fermer" /> */}
                <style>{`
                    .form-group .form__controls .react-tel-input input {
                        display: block;
                        width: 100%;
                        height: 32px;
                        padding: 0.375rem 0.75rem;
                        font-size: 0.875rem;
                        font-weight: 400;
                        line-height: 1.6666666667;
                        color: #FFF;
                        background-color: rgba(216, 216, 216, 0.2);
                        background-clip: padding-box;
                        border: 1px solid #fff;
                        border-radius: 0;
                        -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                    }
                    .react-tel-input .form-control.invalid-number {
                        background-color: rgba(216, 216, 216, 0.2) !important;
                        border: 1px solid #f00 !important;
                    }
                    .react-tel-input .country-list .country-name {
                        color: black;
                    }
                `}</style>
            </div>
        );
    }

    static defaultProps = {
        bodyClass : 'body',
        header    : { content: '' },
        footer    : { content: '' },
    };

    static contextType = NSContext;
}
export default withRouter(withI18next(['common'])(Layout));
