import axios from 'axios';
import getAPIUrl from './getAPIUrl';

function getNsModules() {
    try {
        const nsModules = require('../modules/list_modules');
        return nsModules.default;
    } catch (err) {
        return null;
    }
};

function getMaxProduct(category) {
    const { code } = category || '';
    let max;
    if (code.indexOf('printer') !== -1 || code.indexOf('accessories') !== -1)  max = 3;
    else max = 5; // 'media' ou 'terminals-and-kiosk')
    return max;
}
// function countTotalItemsByCategoryCode(cart, code){
//     let totalByCategoryCode = 0;
//     cart.items.forEach((item) {

//     })
// }

/**
 * Si le produit est dans la categorie printer alors on va compter le nombre de printer dans le panier
 * @param {*} category category du produit qui vient d'être ajouté
 * @returns {number} nombre d'item dans le cart appartenant a la même categorie que le produit voulant être ajouté
 */
async function countProductInCartByCategory(category) {
    try {
        let totalQuantity = 0;
        const cartId = window.localStorage.getItem('cart_id');
        if (!cartId) return totalQuantity;
        const resCart = await axios.post(`${getAPIUrl()}v2/cart/${cartId}`);
        const { data: cart } = resCart;
        if (cart.items && cart.items.length) {
            cart.items.forEach((itemPrd) => {
                // On cherche les items du cart qui son dans la categorie du produit venant d'être ajouté
                const itemFound = category.productsList.find((prd) => itemPrd.id.toString() === prd.id.toString());
                if (itemFound) totalQuantity += itemPrd.quantity;
            });
        }
        return totalQuantity;
    } catch (error) {
        console.error(error);
    }
}

/**
 * Si le produit est dans la categorie printer alors on va compter le nombre de printer dans le panier
 * @param {*} category category du produit qui vient d'être ajouté
 * @returns {number} nombre d'item dans le cart appartenant a la même categorie que le produit voulant être ajouté
 */
async function countProductInCartByProduct(product) {
    try {
        let totalQuantity = 0;
        const cartId = window.localStorage.getItem('cart_id');
        if (!cartId) return totalQuantity;
        const resCart = await axios.post(`${getAPIUrl()}v2/cart/${cartId}`, { PostBody: { populate: ['items.id'] } });
        const { data: cart } = resCart;
        if (cart.items && cart.items.length) {
            cart.items.forEach((itemPrd) => {
                if (itemPrd.id.universe === product.universe) totalQuantity += itemPrd.quantity;
            });
        }
        return totalQuantity;
    } catch (error) {
        console.error(error);
    }
}
/**
 * Permet d'incrémenter selectedQty
 * @param {*} e event
 * @param {*} category category du produit qui vient d'être ajouté
 * @returns {void}
 */
function incrementQty(that, e) {
    try {
        e.stopPropagation();
        const { selectedQty } = that.state;
        return that.setState({ selectedQty: selectedQty + 1 });
    } catch (error) {
        console.error(error);
    }
}

function decrementQty(that, e) {
    const { selectedQty } = that.state;
    e.stopPropagation();
    that.setState({ selectedQty: selectedQty > 1 ? selectedQty - 1 : selectedQty });
}

function truncate(string, size) {
    if (string.length > size) {
        return `${string.substring(0, size)}...`;
    }
    return string;
}

const totalAmountMax = 5000;
export {
    getNsModules, getMaxProduct, incrementQty, decrementQty, totalAmountMax, countProductInCartByCategory, countProductInCartByProduct, truncate
};
