import React from 'react';
import {
    _NSconfig, NSCrossSelling
} from 'aqlrc'; // Import depuis AQT de la config par défaut
import ButtonLoginAccount from './ButtonLoginAccount';
import CartProductCounter from './CartProductCounter';
import Contact from './Contact';
import Gallery from './Gallery';
import MultiMenu from './MultiMenu';
import ProductCard from './ProductCard';
import SelectDate from './SelectDate';

// On surcharge la config par défaut de AQT si besoin
// A noter que <Link> et <CMS> sont déjà gérés directement dans le composant CMS, il faut utiliser respectivement "ns-link" et "ns-cms"
// A garder dans l'ordre alphabétique en fonction du nom du composant SVP
export default {
    ..._NSconfig,
    'ns-btn'           : <ButtonLoginAccount />,
    'ns-cart-counter'  : <CartProductCounter />,
    'ns-contact'       : <Contact />,
    'ns-gallery'       : <Gallery />,
    'ns-multi-menu'    : <MultiMenu />,
    'ns-cross-selling' : <NSCrossSelling ProductCard={ProductCard} />,
    'ns-productcard'   : <ProductCard />,
    'ns-select-date'   : <SelectDate />
};
