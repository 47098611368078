/*
 * Retourne un objet contenant toutes les traductions AQLRC + celles du thème fusionnées
 */

const { assets, mergeRecursive } = require('aqlrc');
const langs = require('../dynamic_langs.js');

const namespaces = ['account', 'addresses', 'cart', 'category', 'delivery', 'common', 'login', 'payment', 'product-card', 'product', 'resetpass', 'static', 'success'];

// Récupération des JSON de traduction dans le thème
const theme_assets = {};
langs.map((lng) => {
    theme_assets[lng.code] = {};
    namespaces.forEach((ns) => {
        try {
            theme_assets[lng.code][namespaces[ns]] = require(`../assets/i18n/${lng.code}/${namespaces[ns]}.json`);
        } catch (e) {}
    });
});

// Suppresion des langues non utilisées dans le thème (ne pas oublier qu'ALRC amène du "fr" ET du "en" !)
for (const lng in assets) {
    if (!langs.find(l => l.code === lng)) {
        delete assets[lng];
    }
}

module.exports = { assets: mergeRecursive(assets, theme_assets), namespaces };
